import React from "react";
import "./App.css";
import {
  Layout,
  Row,
  Col,
  Typography,
  Anchor,
  ConfigProvider,
  theme,
} from "antd";
//import FooterComponent from "./footer.js";
import About from "./main/about";
import Skills from "./main/skills";
import Work from "./main/work";
import Experience from "./main/experience";
import { AiOutlineGithub, AiOutlineLinkedin } from "react-icons/ai";

//import Experience from "./main/experience";
//import Contact from "./main/contact";

const { Header, Content, Footer } = Layout;
const { Title } = Typography;
const { Link } = Anchor;

const App = () => {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 1,
            width: "100%",
            height: "auto",
            top: "0",
          }}
        >
          <Row style={{ color: "white" }}>
            <Col
              flex={2}
              //span={6}
              //xs={8} sm={8} md={8}
            >
              <div
                id="icon"
                //style={{textAlign: "center"}}
              >
                Lucian Ciolacu
              </div>
            </Col>
            <Col flex={2}>
              <Anchor
                direction="horizontal"
                replace
                targetOffset={250}
                //showInkInFixed={false}
                affix={false}
                style={{
                  top: "20px",
                  maxWidth: "max-content",
                  float: "right",
                  position: "sticky",
                }}
              >
                <Link
                  className="menu-link"
                  href="#about"
                  title="About"
                  key="about"
                />
                <Link
                  className="menu-link"
                  href="#work"
                  title="Work"
                  key="work"
                />
                <Link
                  className="menu-link"
                  href="#skills"
                  title="Skills"
                  key="skills"
                />
                <Link
                  className="menu-link"
                  href="#experience"
                  title="Experience"
                  key="experience"
                />
                {/*<Link
                  className="menu-link"
                  href="#references"
                  title="References"
                  key="references"
    />
                <Link
                  className="menu-link"
                  href="#contact"
                  title="Contact"
                  key="contact"
                />*/}
              </Anchor>
            </Col>
          </Row>
        </Header>

        <Content id="main-container">
          <div
            id="about"
            className="content-container"
            //style={{ height: "100vh", background: "rgba(255,0,0,0.02)" }}
          >
            <About />
          </div>
          <div
            id="work"
            className="content-container"
            //style={{ height: "100vh", background: "rgba(251,0,0,0.02)" }}
          >
            <Work />
          </div>
          <div
            id="skills"
            className="content-container"
            //style={{ height: "100vh", background: "rgba(25,0,0,0.02)" }}
          >
            <Skills />
          </div>
          <div
            id="experience"
            className="content-container"
            //style={{ height: "100vh", background: "rgba(455,0,0,0.02)" }}
          >
            <Experience />
          </div>
          {/*<div
            id="references"
            className="content-container"
            //style={{ height: "100vh", background: "rgba(455,0,0,0.02)" }}
          >
  </div>
          <div
            id="contact"
            className="content-container"
            //style={{ height: "100vh", background: "rgba(355,0,0,0.02)" }}
          >
                      </div>*/}
          <Footer id="footer">
            <div className="footer-contact">
              <span>Want to get in touch?</span>
              <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                <a
                  className="footer-icon-link"
                  href="https://github.com/lucianciolacu"
                  target="_blank"
                >
                  <AiOutlineGithub className="footer-icon" />
                </a>
                <a
                  className="footer-icon-link"
                  href="https://www.linkedin.com/in/lciolacu/"
                  target="_blank"
                >
                  <AiOutlineLinkedin className="footer-icon" />
                </a>
              </div>
            </div>
            Lucian Ciolacu © {new Date().getFullYear()}
          </Footer>
        </Content>

        {/*<FooterComponent />*/}
      </Layout>
    </ConfigProvider>
  );
};

export default App;
