import { Carousel, List, Typography, Row, Col, Card } from "antd";
import React, { useState, useEffect } from "react";
import "./work.css";

const Work = (props) => {
  const contentStyle = {
    height: "160px",
    color: "#CC9A48",
    lineHeight: "160px",
    textAlign: "center",
    //background: "#161616",
  };

  const getContentList = (title, data) => {
    return (
      <>
        <List
          header={<h3>{title}</h3>}
          bordered
          dataSource={data}
          style={{ backgroundColor: "white" }}
          renderItem={(item) => <ol>{item}</ol>}
        />
      </>
    );
  };

  let techWriter = [
    "Write technical documentation, APIs, specification sheets",
    "Write technical training or learning paths",
    "Write UI/UX text for web apps", 
    "Set up tooling & CI/CD for Tech Writers",
    "Set up automated migration of documents from old tools to new tools",
    "Set up procedures & Agile WoW for Tech Writers",
    "Lead & manage teams of Tech Writers",
  ];

  let webDev = [
    "Develop frontend web apps in React or plain JS",
    "Develop backend apps & APIs with NodeJS",
    "Write extensions for static site generators in JS",
    "Develop with AWS serverless stack (Lambda/DynamoDB/S3)",
    "Write deployment scripts in Bash",
  ];

  let prodManager = [
    "Gather user feedback and translate it into requirements",
    "Write user stories",
    "Create wire-frames",
    "Manage long and short-term development tasks in each release iteration (SDLC)",
    "Define product strategy",
  ];

  return (
    <Row
      gutter={16}
      //wrap={false}
      id="work-row"
      style={{ marginLeft: "unset", marginRight: "unset" }}
    >
      <Col flex="2">

        <Card
          title="Web developer"
          bordered={true}
          //hoverable={true}
          style={{ minHeight: "380px" }}
        >
          <ul>
            {webDev.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </Card>
      </Col>
      <Col flex="2">
        <Card
          title="Senior Technical Writer"
          bordered={true}
          //hoverable={true}
          theme='dark'
          style={{ minHeight: "380px" }}
        >
          <ul>
            {techWriter.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </Card>
      </Col>
      <Col flex="2">
        <Card
          title="Product Manager"
          bordered={true}
          //hoverable={true}
          onMouseEnter={(e) => console.log("hovered over card")}
          onMouseLeave={(e) => console.log("not hovering anymore")}
          style={{ minHeight: "380px" }}
        >
          <ul>
            {prodManager.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </Card>
      </Col>
    </Row>
  );
};
//export default withRouter(FCOMP)
export default Work;

/*
TW
* Write installation/conceptual/development/deployment/API documentation & specification sheets
* Technical training or learning paths
* Set up tooling & CI/CD for Tech Writers
* Set up automated migration of documents from old tools to new tools
* Set up procedures & Agile WoW for Tech Writers
* Lead & manage teams of Tech Writers

Dev
* Develop frontend web apps in React or plain JS
* Develop backend apps & APIs with NodeJS
* Write extensions for static site generators in JS
* Develop with AWS serverless stack (Lambda/DynamoDB/S3)
* Write deployment scripts in Bash

PM
* Gather user feedback and translate it into requirements
* Write user stories
* Create wire-frames
* Manage long and short-term development tasks in each release iteration (SDLC)
* Define product strategy
*/
